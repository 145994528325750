/* Basic System Colors */
$colorWhite: #FFF;
$colorBlack: #000;

/* Accent Colors */
$colorActionGreen: #61941B;
$colorErrorOrange: #FF4605;
$colorPageTitleBlue: #0470AB;
$colorAlertRed: #AC162C;

/* Grey Colors */
$colorGrey: #CDCED0;
$colorDetailGrey: #D0D7DE;
$colorSupportGrey: #DFDFDF;
$colorInactiveGrey: #A1AFC0;
$colorMessagingGrey: #78899E;
$colorActiveGrey: #65778A;
$colorDarkGrey: #B2B3B7;
$colorBorderGrey: #D1D9E0;
$colorActiveLightGrey: #78899E;
$colorChromeGrey: #ECF1F4;

/* Functional Colors */
$colorLightBlue: #EBF6FC;
$colorLightGreen: #D7E3C5;
$colorSubFlowBlue: #E6EEF7;
$colorScrollbarBlue: #D0DDE7;
$colorSubHeadBlue: #C1DEF0;
$colorActiveDarkBlue: #253B56;
$colorScrollbarWhite: #EBEEF1;
$colorActionBrightBlue: #1994D7;
$colorHoverBlue: #0470AB;
$colorHighlightBlack: #130026;
$colorAliceBlue: #EAF6FC;
$colorSailBlue: #A2D3ED;
$colorPelorousBlue: #2693D1;
$colorActiveTealBlue: #169DBE;
$colorHoverTealBlue: #117E98;
$colorDisabledTealBlue: #B5D8E5;
$colorCountBlue: #253b56;
$colorActiveInteractiveBlue: #169DBE;
$colorLeadGenBackgroundBlue: #006382;

/* Gradient Colors*/
$colorBabyBlue: #ADEBFF;

/* Mobile Optimization */
$colorShadowBlack: rgba(0, 0, 0, 0.10);
$colorDisabledGrey: #ACB9C9;
$colorLinkBrightBlue: #169EBE;
$colorShadowBlueGrey: rgba(120, 137, 158, 0.3);
$colorDarkerShadowBlack: rgba(0, 0, 0, 0.5);
$colorWhiteOverlay: rgba(255, 255, 255, 0.5);

/* ********************** */
/* Colors made of filters */
/* ********************** */

/* Basic System Colors */
$filterColorWhite: invert(100%) sepia(100%) saturate(1%) hue-rotate(289deg) brightness(107%) contrast(101%);

/* Accent Colors */
$filterColorActionGreen: invert(54%) sepia(58%) saturate(5008%) hue-rotate(56deg) brightness(97%) contrast(79%);
$filterColorErrorOrange: invert(29%) sepia(94%) saturate(1794%) hue-rotate(357deg) brightness(102%) contrast(103%);
$filterColorAlertRed: invert(14%) sepia(48%) saturate(6682%) hue-rotate(342deg) brightness(84%) contrast(93%);

/* Grey Colors */
$filterColorSupportGrey: invert(98%) sepia(4%) saturate(0%) hue-rotate(180deg) brightness(96%) contrast(84%);
$filterColorInactiveGrey: invert(76%) sepia(7%) saturate(649%) hue-rotate(173deg) brightness(91%) contrast(87%);
$filterColorActiveGrey: invert(46%) sepia(3%) saturate(2431%) hue-rotate(170deg) brightness(97%) contrast(89%);

/* Functional Colors */
$filterColorHoverTealBlue: invert(40%) sepia(37%) saturate(987%) hue-rotate(144deg) brightness(93%) contrast(92%);
$filterColorActiveDarkBlue: invert(19%) sepia(11%) saturate(2759%) hue-rotate(173deg) brightness(93%) contrast(87%);
$filterColorActionBrightBlue: invert(47%) sepia(24%) saturate(1924%) hue-rotate(159deg) brightness(100%) contrast(90%);
$filterColorHoverBlue: invert(28%) sepia(78%) saturate(1508%) hue-rotate(177deg) brightness(96%) contrast(97%);
$filterColorDisabledTealBlue: invert(91%) sepia(5%) saturate(1677%) hue-rotate(163deg) brightness(94%) contrast(90%);
$filterColorActiveTealBlue: invert(49%) sepia(77%) saturate(3565%) hue-rotate(161deg) brightness(97%) contrast(83%);
$filterColorActiveInteractiveBlue: invert(45%) sepia(33%) saturate(1967%) hue-rotate(154deg) brightness(101%) contrast(83%);
