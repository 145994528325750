@import 'app/shared/styles/colors';
//
// Variables
// --------------------------------------------------


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  0;
$screen-xs-min:              $screen-xs;

// Small screen / tablet
$screen-sm:                  568px;
$screen-sm-min:              $screen-sm;

// Medium screen / desktop
$screen-md:                  768px;
$screen-md-min:              $screen-md;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// $font-size-xlarge:        ceil(($font-size-base * 1.4)); // ~20px;
// $font-size-xxlarge:       ceil(($font-size-base * 1.7)); // ~24px;

// $font-weight-hairline:    100;
// $font-weight-light:       200;
// $font-weight-book:        300;
// $font-weight-normal:      400;
// $font-weight-medium:      500;
// $font-weight-bold:        700;
// $font-weight-bolder:      900;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$border-radius-xlarge:      12px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    $colorActionBrightBlue;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $colorWhite;

// Override the maximum width of `.container` for desktop and md defined in Bootstrap.
// Medium screen / desktop
// $container-desktop:            ((930px + $grid-gutter-width));
// // For `$screen-md-min` and up.
// $container-md:                 $container-desktop;

// Responsive utilities
//
// Responsive: Utility classes
// --------------------------------------------------


// IE10 in Windows (Phone) 8
//
// Support for responsive views via media queries is kind of borked in IE10, for
// Surface/desktop in split view and for Windows Phone 8. This particular fix
// must be accompanied by a snippet of JavaScript to sniff the user agent and
// apply some conditional CSS to *only* the Surface/desktop Windows 8. Look at
// our Getting Started page for more information on this bug.
//
// For more information, see the following:
//
// Issue: https://github.com/twbs/bootstrap/issues/10497
// Docs: https://getbootstrap.com/docs/3.4/getting-started/#support-ie10-width
// Source: https://timkadlec.com/2013/01/windows-phone-8-and-device-width/
// Source: https://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/

@at-root {
    @-ms-viewport {
      width: device-width;
    }
  }
  
  
  // Visibility utilities
  .visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
 display:none!important
}
@media (min-width:1200px) {
 .col-lg-1 {
  width:8.33333333%
 }
 .col-lg-2 {
  width:16.66666667%
 }
 .col-lg-3 {
  width:25%
 }
 .col-lg-4 {
  width:33.33333333%
 }
 .col-lg-5 {
  width:41.66666667%
 }
 .col-lg-6 {
  width:50%
 }
 .col-lg-7 {
  width:58.33333333%
 }
 .col-lg-8 {
  width:66.66666667%
 }
 .col-lg-9 {
  width:75%
 }
 .col-lg-10 {
  width:83.33333333%
 }
 .col-lg-11 {
  width:91.66666667%
 }
 .col-lg-12 {
  width:100%
 }
}
@media (min-width:768px) and (max-width:1199px) {
 .col-md-1 {
  width:8.33333333%
 }
 .col-md-2 {
  width:16.66666667%
 }
 .col-md-3 {
  width:25%
 }
 .col-md-4 {
  width:33.33333333%
 }
 .col-md-5 {
  width:41.66666667%
 }
 .col-md-6 {
  width:50%
 }
 .col-md-7 {
  width:58.33333333%
 }
 .col-md-8 {
  width:66.66666667%;
 }
 .col-md-9 {
  width:75%
 }
 .col-md-10 {
  width:83.33333333%
 }
 .col-md-11 {
  width:91.66666667%
 }
 .col-md-12 {
  width:100%
 }
}
@media (min-width:568px) and (max-width:767px) {
 .col-sm-1 {
  width:8.33333333%
 }
 .col-sm-2 {
  width:16.66666667%
 }
 .col-sm-3 {
  width:25%
 }
 .col-sm-4 {
  width:33.33333333%
 }
 .col-sm-5 {
  width:41.66666667%
 }
 .col-sm-6 {
  width:50%
 }
 .col-sm-7 {
  width:58.33333333%
 }
 .col-sm-8 {
  width:66.66666667%
 }
 .col-sm-9 {
  width:75%
 }
 .col-sm-10 {
  width:83.33333333%
 }
 .col-sm-11 {
  width:91.66666667%
 }
 .col-sm-12 {
  width:100%
 }
}
@media (max-width:567px) {
 .col-xs-1 {
  width:8.33333333%
 }
 .col-xs-2 {
  width:16.66666667%
 }
 .col-xs-3 {
  width:25%
 }
 .col-xs-4 {
  width:33.33333333%
 }
 .col-xs-5 {
  width:41.66666667%
 }
 .col-xs-6 {
  width:50%
 }
 .col-xs-7 {
  width:58.33333333%
 }
 .col-xs-8 {
  width:66.66666667%
 }
 .col-xs-9 {
  width:75%
 }
 .col-xs-10 {
  width:83.33333333%
 }
 .col-xs-11 {
  width:91.66666667%
 }
 .col-xs-12 {
  width:100%
 }
}
@media (max-width:567px) {
 .visible-xs {
  display:block!important
 }
 table.visible-xs {
  display:table
 }
 tr.visible-xs {
  display:table-row!important
 }
 td.visible-xs,
 th.visible-xs {
  display:table-cell!important
 }
}
@media (max-width:567px) {
 .visible-xs-block {
  display:block!important
 }
}
@media (max-width:567px) {
 .visible-xs-inline {
  display:inline!important
 }
}
@media (max-width:567px) {
 .visible-xs-inline-block {
  display:inline-block!important
 }
}
@media (min-width:568px) and (max-width:767px) {
 .visible-sm {
  display:block!important
 }
 table.visible-sm {
  display:table
 }
 tr.visible-sm {
  display:table-row!important
 }
 td.visible-sm,
 th.visible-sm {
  display:table-cell!important
 }
}
@media (min-width:568px) and (max-width:767px) {
 .visible-sm-block {
  display:block!important
 }
}
@media (min-width:568px) and (max-width:767px) {
 .visible-sm-inline {
  display:inline!important
 }
}
@media (min-width:568px) and (max-width:767px) {
 .visible-sm-inline-block {
  display:inline-block!important
 }
}
@media (min-width:768px) and (max-width:1199px) {
 .visible-md {
  display:block!important
 }
 table.visible-md {
  display:table
 }
 tr.visible-md {
  display:table-row!important
 }
 td.visible-md,
 th.visible-md {
  display:table-cell!important
 }
}
@media (min-width:768px) and (max-width:1199px) {
 .visible-md-block {
  display:block!important
 }
}
@media (min-width:768px) and (max-width:1199px) {
 .visible-md-inline {
  display:inline!important
 }
}
@media (min-width:768px) and (max-width:1199px) {
 .visible-md-inline-block {
  display:inline-block!important
 }
}
@media (min-width:1200px) {
 .visible-lg {
  display:block!important
 }
 table.visible-lg {
  display:table
 }
 tr.visible-lg {
  display:table-row!important
 }
 td.visible-lg,
 th.visible-lg {
  display:table-cell!important
 }
}
@media (min-width:1200px) {
 .visible-lg-block {
  display:block!important
 }
}
@media (min-width:1200px) {
 .visible-lg-inline {
  display:inline!important
 }
}
@media (min-width:1200px) {
 .visible-lg-inline-block {
  display:inline-block!important
 }
}
@media (max-width:567px) {
 .hidden-xs {
  display:none!important
 }
}
@media (min-width:568px) and (max-width:767px) {
 .hidden-sm {
  display:none!important
 }
}
@media (min-width:768px) and (max-width:1199px) {
 .hidden-md {
  display:none!important
 }
}
@media (min-width:1200px) {
 .hidden-lg {
  display:none!important
 }
}

// Float styles
@media (min-width:568px) and (max-width:767px) {
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
     float:left
    }
}
@media (min-width:768px) and (max-width:1199px) {
 .col-md-1,
 .col-md-10,
 .col-md-11,
 .col-md-12,
 .col-md-2,
 .col-md-3,
 .col-md-4,
 .col-md-5,
 .col-md-6,
 .col-md-7,
 .col-md-8,
 .col-md-9 {
  float:left
 }
}
@media (min-width: 1200px) {
.col-lg-1,
 .col-lg-10,
 .col-lg-11,
 .col-lg-12,
 .col-lg-2,
 .col-lg-3,
 .col-lg-4,
 .col-lg-5,
 .col-lg-6,
 .col-lg-7,
 .col-lg-8,
 .col-lg-9 {
  float:left
 }
}