@import 'app/shared/styles/colors';
@import 'app/shared/styles/variables';

/* You can add global styles to this file, and also import other style files */
html {
    --font-family-base: var(--font-family-base-theme, 'InspireTWDC');
}

body {
    font-family: 'InspireTWDC', sans-serif;
    height: 100%;
    width: 100%;
}

h1, h2, h3, h4, h5, h6, span, a, li, p, input, textarea {
    font-family: inherit;
}

ol, ul {
    list-style: none;
}
